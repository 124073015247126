<template>
	<v-container
			fluid
	>
		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-text>
						<v-data-table
								:loading="loading"
								:headers="headers"
								:items="items"
								:server-items-length="totalItems"
								:search="search"
								item-key="id"
								:options.sync="options"
								class="row-pointer"
								:footer-props="{'items-per-page-options':[50, 100, 500]}"
						>
							<template v-slot:top>
								<v-row no-gutters>
									<v-col cols="12">
										<v-text-field no-gutters clearable v-model="search" label="Search" class="ps-4 pt-6 pr-4"></v-text-field>
									</v-col>
								</v-row>
							</template>
							<template v-slot:[`item.ts`]="{ item }">
								<span class="text-no-wrap">{{ item.ts }}</span>
							</template>
							<template v-slot:[`item.url`]="{ item }">
								<span class="text-no-wrap">{{ item.url }}</span>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { globalMixIn } from '@/App.vue'

	export default {
		data: () => ({
			search: ``,
			loading: false,
			options: {
				sortDesc: [true],
				sortBy: ['date_created'],
			},
			headers: [
				{ text: 'Timestamp', value: 'ts', sortable: false },
				{ text: 'IP', value: 'ip', sortable: false },
				{ text: 'Account', value: 'account', sortable: false },
				{ text: 'Reference', value: 'reference', sortable: false },
				{ text: 'Reference ID', value: 'reference_id', sortable: false },
				{ text: 'Key', value: 'key', sortable: false },
				{ text: 'Value', value: 'value', sortable: false },
				{ text: 'Method', value: 'method', sortable: false },
				{ text: 'URL', value: 'url', sortable: false, nowrap: true },
			],
			totalItems: 0,
			items: [],
		}),
		methods: {
			refreshContent () {
				this.loading = true
				this.$httpInt.get(`/logs/`, { params: this.$paginationParams(this, { 'skip_options': true }) })
					.then(res => {
						this.items = res.data.result
						this.totalItems = res.data.pagination.total_items
					})
					.finally(() => this.loading = false)
			},
		},
		mixins: [globalMixIn],
		mounted () {
		},
		name: `SystemInternalLogs`,
		watch: {
			search: {
				handler (value) {
					if (value && value.length < 3) return
					this.$debounce(() => this.refreshContent(), 300)
				},
				deep: true,
			},
			options: {
				handler () {
					this.refreshContent()
				},
				deep: true,
			},
		},
	}
</script>
